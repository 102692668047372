import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import './Showreel.css';

// Import the image from the folder
import thumbnailImage from '../assets/showreel.png'; // Adjust the path accordingly
import playButtonIcon from '../assets/play-button.png';

const Showreel = () => {
  const { t, i18n } = useTranslation();
  const currentLang = i18n.language;
  const isRtl = currentLang === 'ar';
  
  const [isPlaying, setIsPlaying] = useState(false);

  // Your Google Drive video file ID without autoplay
  const googleDriveVideoUrl = `https://drive.google.com/file/d/18NAJNGgZNnqgbvN459xaZtszIoEbf60X/preview`;

  const handlePlay = () => {
    setIsPlaying(true); // Load the video only when the play button is clicked
  };

  return (
    <div className="showreel-container" dir={isRtl ? 'rtl' : 'ltr'}>
      <div className="showreel-text" style={{ fontFamily: isRtl ? 'Tajawal, sans-serif' : 'Raleway, sans-serif' }}>
        <h1 className='title'>{t('showreelTitle')}</h1>
        <h4 className='smalltex'>{t('showreelDescription')}</h4>
      </div>

      {!isPlaying ? (
        <div className="showreel-thumbnail-container" onClick={handlePlay}>
          <img
            src={thumbnailImage}  // Use the imported image here
            alt="Showreel Thumbnail"
            className="showreel-thumbnail"
          />
          <button className="play-button">
            <img src={playButtonIcon} alt="Play" /> {/* Use the imported play button icon */}
          </button>
        </div>
      ) : (
        <iframe
          className="showreel-video"
          src={googleDriveVideoUrl}  // Only load the video after the play button is clicked
          allow="autoplay"
          allowFullScreen
          title="Showreel Video"
        ></iframe>
      )}
    </div>
  );
};

export default Showreel;
